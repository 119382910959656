<template>
   <div class="emb-FinalReceipt-wrap" v-if="loaded">
      <div class="final-receipt  section-gap">
         <v-container grid-list-xl py-0>
            <v-layout align-center justify-center>
               <v-flex xs12 sm12 md10 lg7 xl7>
                  <div class="emb-card pb-12">
                     <div  id="payment-receipt">
                        <div class="text-center bg-grey pa-sm-12 pa-6">
                           <h1>¡Gracias!</h1>
                           <h5 class="mb-6">El pedido se ha generado con éxito. </h5>
                           <h6 class="mb-6">Operación: 0000{{ $route.params.id }}</h6>
                           <img src="/static/images/checked.png" width="64" height="64" alt="Success">
                        </div>
                        <div class="py-sm-12 py-8 px-sm-6 px-4">
                           <v-layout row wrap mb-6>
                              <v-flex xs12 sm6 md6 lg6 xl6>
											<h6>Resumen</h6>
                                 <p class="mb-1 text-capitalize">Operación: 0000{{ $route.params.id }}</p>
                                 <p class="mb-1">Fecha: {{deliveryDate()}}</p>
                                 <p class="mb-1">Total Pedido: <emb-currency-sign></emb-currency-sign> {{order.total}} </p>
                              </v-flex>
                              <v-flex xs12 sm6 md6 lg6 xl6>
											<h6>Datos de contacto</h6>
											<p class="mb-1">{{order.shipping.contact.name}}</p>
											<p class="mb-1">{{order.shipping.contact.email}}</p>
                                 <p class="mb-1">{{order.shipping.contact.telephone}}</p>
                                 <!-- <p class="mb-1">Contact No. {{order.customer.phone}}</p> -->
                              </v-flex>
                           </v-layout>
                           <div class="py-6 text-center bg-grey px-3">
                              <h4>Tiempo aproximada de entrega</h4>
                              <h3> 30 min.</h3>
                           </div>
                           <h4 class="pt-6">Los detalles de su pedido</h4>
                           <v-simple-table dense>
                              <template v-slot:default>
                                 <thead>
                                    <tr>
                                       <th class="text-center">Producto</th>
                                       <th class="text-right" width="25%">Cantidad</th>
                                       <th class="text-right">Precio Unit.</th>
                                       <th class="text-right">Total</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr v-for="(item, index) in order.items" :key="index">
                                       <td>{{ item.item.name }}</td>
                                       <td class="text-right">
                                          {{item.quantity}}
                                       </td>
                                       <td class="text-right">
                                          {{item.unit_price}}
                                       </td>
                                       <td class="text-right"><emb-currency-sign></emb-currency-sign> {{ item.total }}</td>
                                    </tr>
                                 </tbody>
                              </template>
                           </v-simple-table>
									<v-divider class="my-4"></v-divider>
									<div class="d-flex align-center justify-space-between">
										<h4>Total</h4>
										<h4><emb-currency-sign></emb-currency-sign>{{order.total}}</h4>
									</div>
                        </div>
                     </div>
                     <div class="layout row wrap pt-6 px-4 text-center">
								<!-- <v-flex xs12 sm6 md6 lg6 xl6 text-md-left>
                        	<v-btn class="accent" large>Download PDF</v-btn>
								</v-flex> -->
								<v-flex xs12 sm12 md12 lg12 xl12 text-md-right>
									<v-btn class="accent" large to="/">Ir a inicio</v-btn>
								</v-flex>
                     </div>
                  </div>
               </v-flex>
            </v-layout>
         </v-container>
      </div>
   </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from 'dayjs';

export default {
   computed: {
      ...mapGetters(["location"])
   },
   data() {
      return {
         resource: 'user/orders',
         order: {},
         date_of_issue: null,
         loaded: false
      };
   },
   async created() {
      await this.getOrder()
   },
   methods: {
      async getOrder() {
         await this.$http.get(`/${this.resource}/record/${this.$route.params.id}`).then(( response) => {
            this.order = response.data

            this.loaded = true
         })
      },
      deliveryDate() {
         this.date_of_issue = this.order.date_of_issue
         return dayjs(this.date_of_issue).format("DD/MM/YYYY");
      },
      // itemTotal() {
      //    let productTotal = 0;
      //    if (this.orderData.products && this.orderData.products.length > 0) {
      //    for (const item of this.orderData.products) {
      //       productTotal += item.price * item.quantity;
      //    }
      //    return productTotal.toFixed(2);
      //    } else {
      //    return productTotal;
      //    }
      // },
      // getTotalPrice() {
      //    let totalPrice = this.tax + this.shipping;
      //    if (this.orderData.products && this.orderData.products.length > 0) {
      //       for (const item of this.orderData.products) {
      //          totalPrice += item.price * item.quantity;
      //       }
      //       return totalPrice.toFixed(2);
      //    } else {
      //       return totalPrice.toFixed(2);
      //    }
      // }
   }
};
</script>
